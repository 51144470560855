<template lang="pug">
v-speed-dial(
  :class="large ? 'mx-auto' : ''"
  v-model="contact"
  bottom
  :right="!large"
  direction="top"
  :fixed="!large"
  transition="slide-y-reverse-transition"
  itemscope
  itemtype="http://schema.org/LocalBusiness"
)
  span.d-none(itemprop="name") John IT Consultancy
  template(v-slot:activator)
    v-tooltip(
      v-model="showTooltip"
      color="primary"
      transition="slide-x-reverse-transition"
      left
    )
      template(v-slot:activator="{ on, attrs }")
        v-btn(
          v-model="contact"
          color="primary"
          :fab="!large"
          large
          dark
          :bottom="!large"
          :right="!large"
        )
          v-icon(
            v-if="contact"
            :left="large"
          ) mdi-close
          v-icon(
            v-if="!contact"
            :left="large"
          ) mdi-chat-outline
          template(v-if="large")
            | Neem contact op
      span.overline Neem direct contact op
      v-icon(small right color="white") mdi-arrow-right

  v-tooltip(
    :left="!large"
    :top="large"
    color="primary"
  )
    template(v-slot:activator="{ on, attrs }")
      v-btn(
        fab
        color="secondary"
        small
        v-on="on"
        v-bind="attrs"
        rel="nofollow"
        itemprop="email"
        :href="mailto"
        target="_blank"
      )
        v-icon mdi-mail
    | Mail me

  v-tooltip(
    :left="!large"
    :top="large"
    color="primary"
  )
    template(v-slot:activator="{ on, attrs }")
      v-btn(
        fab
        color="accent"
        small
        v-on="on"
        v-bind="attrs"
        rel="nofollow"
        itemprop="telephone"
        :href="tel"
        target="_blank"
      )
        v-icon mdi-phone
    | Bel me
</template>

<script>
export default {
  name: 'Contact',

  props: {
    large: {
      type: Boolean,
      default: false,
    },
    scrolled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    contact: false,
    showTooltip: false,
  }),
  mounted() {
    setTimeout(() => {
      this.showTooltip = !this.large;
    }, 1500);
  },
  computed: {
    mailto() {
      return atob('bWFpbHRvOmpvaG5Aam9obml0Y29uc3VsdGFuY3kubmw=');
    },
    tel() {
      return atob('dGVsOiszMTY0OTMwMDYzNg==');
    },
  },
  watch: {
    scrolled(s) {
      if (s && this.showTooltip) {
        this.showTooltip = false;
      }
    },
  },
};
</script>
