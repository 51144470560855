<template lang="pug">
v-app(v-scroll="onScroll")
  v-system-bar(app dark lights-out v-if="showBar")
    v-icon mdi-wrench
    span.mr-3 development
    v-icon mdi-responsive
    span.mr-3 {{ $vuetify.breakpoint.name }}
    v-spacer
    v-icon(@click="showBar = false") mdi-close
  v-img(
    gradient="180deg,\
      rgba(0,77,189,1) 0%,\
      rgba(0,63,140,1) 2%,\
      rgba(0,52,112,1) 7%,\
      rgba(0,39,79,1) 13%,\
      rgba(0,32,60,1) 20%,\
      rgba(0,27,46,1) 30%,\
      rgba(0,27,46,1) 50%,\
      rgba(0,27,46,1) 70%,\
      rgba(19,33,74,1) 80%,\
      rgba(31,37,91,1) 87%,\
      rgba(51,44,121,1) 93%,\
      rgba(71,50,149,1) 98%,\
      rgba(97,59,187,1) 100%"
    height="100vh"
    dark
  )
    v-container(
      fill-height
    )
      v-row.fill-height(align="center" justify="center")
        v-col(cols="12")
          v-row(justify="center")
            v-col(cols="12" sm="8" md="7" lg="6" xl="4" order="12" order-sm="1")
              v-slide-y-reverse-transition
                v-card(flat color="transparent" v-show="showIntro")
                  v-card-text
                    h1.display-1.white--text.font-weight-thin
                      | {{ greeting }}! Als IT-consultant ben ik je graag van
                      | dienst. Bekijk snel waarmee ik je kan helpen.
                  v-card-actions
                    v-btn(
                      text
                      outlined
                      @click="$vuetify.goTo('#skills')"
                    )
                      v-icon(left) mdi-lightbulb-on-outline
                      | Diensten
                    v-btn(
                      text
                      outlined
                      href="https://www.linkedin.com/in/johnciocoiu/"
                      target="_blank"
                    )
                      //- @click="$vuetify.goTo('#experience')"
                      v-icon(left) mdi-format-list-checks
                      | Ervaring
                      v-icon(right small) mdi-open-in-new
            v-col(cols="5" sm="3" md="2" xl="1" order="1" order-sm="12")
              v-slide-y-reverse-transition
                v-img(:src="require(`@/assets/logo-badge-bg.svg`)" contain v-show="showJohn")
  v-main
    v-container#skills
      v-row(justify="center")
        v-col(cols="12" sm="7" md="4" lg="4")
          v-card.fill-height()
            v-card-title.headline.font-weight-thin
              | Full-stack development
            //- v-card-text
            //-   | Met de software engineering ervaring in grote en complexe
            //- v-divider
            v-card-text.pt-4
              v-chip.mr-2.mb-2(v-for="(t, i) in fullStackTags" :key="i")
                v-icon(left) {{ t.icon }}
                | {{ t.tag }}

        v-col(cols="12" sm="7" md="4" lg="4")
          v-card.fill-height()
            v-card-title.headline.font-weight-thin
              | Data &amp; Analytics
            //- v-card-text
            //- v-divider
            v-card-text.pt-4
              v-chip.mr-2.mb-2(v-for="(t, i) in dataTags" :key="i")
                v-icon(left) {{ t.icon }}
                | {{ t.tag }}
        v-col(cols="12" sm="7" md="4" lg="4")
          v-card.fill-height()
            v-card-title.headline.font-weight-thin
              | IT Advies
            //- v-card-text
            //-   | Lorem ipsum dolor sit amet, no nam oblique veritus. Commune
            //-   | scaevola imperdiet nec ut, sed euismod convenire principes at.
            //- v-divider
            v-card-text.pt-4
              v-chip.mr-2.mb-2(v-for="(t, i) in itTags" :key="i")
                v-icon(left) {{ t.icon }}
                | {{ t.tag }}

      v-toolbar.mt-10(
        flat
      )
        Contact(:scrolled="scrolled" large)

    //- v-container#experience
    //-   v-timeline(
    //-     :dense="$vuetify.breakpoint.smAndDown"
    //-   )
    //-     v-timeline-item(
    //-       color="primary"
    //-       icon="mdi-school"
    //-       icon-color="white"
    //-       fill-dot
    //-       v-for="n in [1,2,3,4,5]"
    //-       :key="`item-${n}`"
    //-       :reverse="!!n"
    //-     )
    //-       div.mt-1.font-weight-light(
    //-         slot="opposite"
    //-       )
    //-         | {{ 2020-n }}
    //-       v-card
    //-         v-card-title.headline.font-weight-thin
    //-           | Lorem ipsum
    //-         v-card-text.font-weight-light
    //-           | Lorem ipsum dolor sit amet, no nam oblique veritus. Commune
    //-           | scaevola imperdiet nec ut, sed euismod convenire principes at.
    //-           | Est et nobis iisque percipit, an vim zril disputando voluptatibus,
    //-           | vix an salutandi sententiae.

    //-   v-toolbar.mt-10(
    //-     flat
    //-   )
    //-     Contact(:scrolled="scrolled" large)

    Contact(ref="bottomContact" :scrolled="scrolled")

    v-footer(dark padless)
      v-container(fluid)
        v-row
          v-col(cols="12")
            v-card.transparent.white--text.text-center(flat tile)
              v-card-text
                v-btn.mx-4.white--text(
                  v-for="(icon, index) in footerIcons"
                  :key="index" icon
                  :href="icon.href"
                  large
                  target="_blank"
                )
                  v-icon
                    | {{ icon.icon }}
                p.font-weight-light.mt-4.mb-0
                  strong.mx-2 KVK
                  span 81797834
                  strong.ml-4.mr-2 |
                  strong.mx-2 BTW
                  span NL003606257B57
            v-divider.mb-2
            v-card.transparent.white--text.text-center(flat tile)
              v-card-text.font-weight-light
                | {{ new Date().getFullYear() }} &mdash;&nbsp;
                strong John IT Consultancy

</template>

<script>
import Contact from '@/components/Contact.vue';

export default {
  name: 'App',

  components: {
    Contact,
  },

  data: () => ({
    contact: false,
    showIntro: false,
    showJohn: false,
    showBar: process.env.NODE_ENV === 'development',
    offsetTop: 0,
    scrolled: false,
    fullStackTags: [
      { icon: 'mdi-docker', tag: 'Containers' },
      { icon: 'mdi-infinity', tag: 'DevOps' },
      { icon: 'mdi-web', tag: 'WebApps' },
      { icon: 'mdi-language-javascript', tag: 'JavaScript' },
      { icon: 'mdi-language-typescript', tag: 'TypeScript' },
      { icon: 'mdi-language-python', tag: 'Python' },
      { icon: 'mdi-vuejs', tag: 'Vue 2 / 3' },
      { icon: 'mdi-angular', tag: 'Angular' },
      { icon: 'mdi-material-design', tag: 'Material Design' },
      { icon: 'mdi-language-html5', tag: 'HTML5 & CSS3' },
      { icon: 'mdi-language-go', tag: 'Go' },
      { icon: 'mdi-database', tag: 'SQL' },
      { icon: 'mdi-file-document-multiple-outline', tag: 'NoSQL' },
      { icon: 'mdi-api', tag: 'APIs' },
      { icon: 'mdi-kubernetes', tag: 'Kubernetes' },
      { icon: 'mdi-cog-refresh', tag: 'CI/CD' },
      { icon: 'mdi-language-php', tag: 'PHP' },
      { icon: 'mdi-wordpress', tag: 'WordPress' },
      { icon: 'mdi-source-branch', tag: 'GIT' },
      { icon: 'mdi-graphql', tag: 'GraphQL' },
      { icon: 'mdi-linux', tag: 'Unix' },
    ],
    dataTags: [
      { icon: 'mdi-cloud', tag: 'Cloud' },
      { icon: 'mdi-database', tag: 'Databases' },
      { icon: 'mdi-graph', tag: 'Graph' },
      { icon: 'mdi-file-document-multiple-outline', tag: 'Document Store' },
      { icon: 'mdi-chart-box-outline', tag: 'Data Analytics' },
      { icon: 'mdi-apache-kafka', tag: 'Kafka' },
      { avatar: 'ML', tag: 'MarkLogic' },
      { icon: 'mdi-brain', tag: 'Machine Learning' },
    ],
    itTags: [
      { icon: 'mdi-cloud', tag: 'Cloud' },
      { icon: 'mdi-microsoft-azure', tag: 'Azure' },
      { icon: 'mdi-google-cloud', tag: 'Google Cloud Platform' },
      { icon: 'mdi-google-cloud', tag: 'Data Platform' },
    ],
  }),

  created() {
    document.title = 'John IT Consultancy';
  },

  mounted() {
    setTimeout(
      () => {
        this.showIntro = true;
      },
      this.$vuetify.breakpoint.smAndUp ? 1000 : 750,
    );

    setTimeout(
      () => {
        this.showJohn = true;
      },
      this.$vuetify.breakpoint.smAndUp ? 500 : 750,
    );
  },

  computed: {
    greeting() {
      const h = new Date().getHours();
      if (h < 12) {
        return 'Goedemorgen';
      }
      if (h < 18) {
        return 'Goedemiddag';
      }
      return 'Goedenavond';
    },
    mailto() {
      return atob('bWFpbHRvOmpvaG5Aam9obml0Y29uc3VsdGFuY3kubmw=');
    },
    tel() {
      return atob('dGVsOiszMTY0OTMwMDYzNg==');
    },
    footerIcons() {
      return [
        { icon: 'mdi-linkedin', href: 'https://www.linkedin.com/company/john-it-consultancy/' },
        { icon: 'mdi-phone', href: this.tel },
        { icon: 'mdi-mail', href: this.mailto },
      ];
    },
  },

  methods: {
    onScroll() {
      this.scrolled = true;
    },
  },
};
</script>

<style lang="scss">
.v-image__image--preload {
  filter: none !important;
}
</style>
